<template>
  <svg version="1.1" viewBox="0 0 24 24" class="svg-icon svg-fill">
    <path
      :fill="color"
      stroke="none"
      data-testid="icon-path"
      pid="0"
      d="M8.93 10.19a.5.5 0 0 1 .07.43.65.65 0 0 1-.68.58h-1l.22-1.2h1a.5.5 0 0 1 .39.19zm13.07 0V16a1 1 0 0 1-1 1h-1a3 3 0 1 1-6 0H9a3 3 0 0 1-6 0v-.09a1.48 1.48 0 0 1-1-1.41V14h.34c.22 0 .3-.07.33-.29L3 12.09h1.59a.28.28 0 0 0 .33-.28l.08-.47c0-.22 0-.29-.23-.29H3.14l.18-1h2c.22 0 .3-.08.33-.28l.09-.47c0-.22 0-.29-.24-.29H2V5.5A1.5 1.5 0 0 1 3.5 4h10A1.5 1.5 0 0 1 15 5.5V6h3a1 1 0 0 1 .71.29l2.85 2.86a1.5 1.5 0 0 1 .44 1.06v-.02zM7.5 17a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm1.32-8H6.87c-.22 0-.29.07-.34.29l-.78 4.42c0 .22 0 .29.24.29h.57c.22 0 .29-.07.33-.29l.27-1.49h.34c.39 0 .47.08.57.42l.33 1.08c.06.22.18.28.54.28h.56c.213 0 .283-.1.21-.3l-.48-1.39a1.89 1.89 0 0 0-.11-.25 1.72 1.72 0 0 0 1.07-1.38A1.29 1.29 0 0 0 8.82 9zm5.61 0H11.6c-.22 0-.29.07-.34.29l-.78 4.42c0 .22 0 .29.24.29h2.9c.22 0 .29-.07.33-.29l.08-.47c0-.21 0-.29-.23-.29h-2.05l.17-1h1.63c.21 0 .3-.07.33-.29l.09-.47c0-.22 0-.28-.23-.28h-1.63l.15-.91h2c.22 0 .31-.08.34-.28l.09-.47c.01-.18-.04-.25-.26-.25zm4.07 8a1.5 1.5 0 1 0-2.999 0 1.5 1.5 0 0 0 2.999 0zm.25-8h-2.83c-.22 0-.29.07-.34.29l-.78 4.42c0 .22 0 .29.24.29h2.9c.22 0 .29-.07.33-.29l.08-.47c0-.21 0-.29-.23-.29h-2.05l.17-1h1.63c.22 0 .3-.07.34-.29l.08-.47c0-.22 0-.28-.23-.28h-1.63l.16-.91h2a.28.28 0 0 0 .33-.28l.08-.43c0-.22 0-.29-.25-.29z"
      _fill
    />
  </svg>
</template>

<script>
export default {
  name: 'FreeShippingSvg',
  props: {
    color: {
      type: String,
      default: '#ea5164',
    },
  },
};
</script>
