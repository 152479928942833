<template lang="pug">
  component.c-product-card(:is="tag")
    bl-card-head-mv.c-product-card__image
      .c-product-card__badges--top
        bl-badge-av(v-if="hasDeal" color="pink" size="large") {{ product.deal.percentage }}%
        .c-wholesale-label(v-if="hasWholesale") Grosir
      bl-media-mv(
        :aspect-ratio="1"
        :transition="false"
        :alt="product.name"
        :src="imgUrl"
        :webp="true"
        :lazy-src="imageLazy('medium')"
        contain
      )
        template(slot="placeholder")
          div.c-media__placeholder__content
            bl-spinner-av(size="medium")

      .c-product-card__badges--bottom
        .c-badges__bukamall(v-if="isBrandSeller")
          img(:src="`https://s1.bukalapak.com/pavilion/1586920121610/original/bukamall-logo.png`" alt="BukaMall")
        template(v-else-if="isSuperSeller && isFinishedFetchBadgeToggle")
          .c-badges__new-super-seller(v-if="isSuperSellerNewBadgeActive")
          .c-badges__super-seller(v-else) Super Seller
        .c-label.c-product-card__label__second(v-if="isSecondProduct") Bekas

      .c-product-card__overlay(v-if="showOverlay")
        a.c-product-card__overlay__link(:href="product.url" @click="handleClick")
        .c-product-card__overlay__content(v-if="product.store")
          a.c-product-card__store-name(:href="product.store.url") {{ product.store.name }}
          p.c-product-card__store-location {{ product.store.address.city }}
          .c-product-card__store-level.u-mrgn-top--1
            img.c-product-card__store-level__icon(
              v-if="product.store.level.image_url"
              :src="product.store.level.image_url"
              :title="product.store.level.name"
              width="24"
              height="24"
            )
            a.c-product-card__store-level__link(
              :href="feedbackUrl"
              target="_blank"
              rel="noopener noreferer"
            )
              | {{ storeReview.percentage }}% ({{ storeReview.total }} feedback)
          hr.u-hr--bleed.u-mrgn-top--2.u-mrgn-bottom--2(v-if="isBrandSeller || isSuperSeller")
          .c-product-card__store-brand(v-if="isBrandSeller")
            img(:src="bukamallLogo" alt="BukaMall")
          template(v-else-if="isSuperSeller && isFinishedFetchBadgeToggle")
            .c-badges__new-super-seller(v-if="isSuperSellerNewBadgeActive")
            .c-badges__super-seller(v-else) Super Seller

    bl-card-body-mv.c-product-card__content
      .c-product-card__rating(v-if="product.rating.average_rate && showRating")
        bl-rating-mv(
          :value="ratingVal"
          :max-ratings="5"
          :title="product.rating.average_rate"
          variant="star"
        )
        a.u-mrgn-left--1(
          :href="reviewUrl"
          target="_blank"
          rel="noopener noreferer"
        ).u-mrgn--0 ({{ product.rating.user_count }} ulasan)
      h3.c-product-card__name
        a(:href="product.url" @click="handleClick") {{ product.name }}
      .c-product-card__price.-deal(v-if="hasDeal")
        span.original_price {{ price('original') }}
        span.discount_price {{ price('discount_price') }}
      .c-product-card__price(v-else)
        span.original_price {{ price() }}
      slot(name="content-footer")
      .u-mrgn-top--2(v-if="isDigitalGoods")
        bl-tag-av Produk Digital
          template(v-slot:icon="")
            phone-iphone-svg
        bl-tag-av Tanpa Ongkir
          template(v-slot:icon="")
            free-shipping-svg
</template>

<script>
import BlBadgeAv from '@bukalapak/bazaar-dweb/dist/components/BlBadgeAv';
import BlIconAv from '@bukalapak/bazaar-dweb/dist/components/BlIconAv';
import BlRatingMv from '@bukalapak/bazaar-dweb/dist/components/BlRatingMv';
import BlCardHeadMv from '@bukalapak/bazaar-dweb/dist/components/BlCardHeadMv';
import BlCardBodyMv from '@bukalapak/bazaar-dweb/dist/components/BlCardBodyMv';
import BlSpinnerAv from '@bukalapak/bazaar-dweb/dist/components/BlSpinnerAv';
import { money } from '@bukalapak/toolbox-helper/number-helper';
import BlTagAv from './BlTagAv';

import imageHelper from '~pdp-mixins/image-helper';
import BlMediaMv from '~shared-js/components/BlMediaMv';
import PhoneIphoneSvg from '~shared-js/components/PhoneIphoneSvg';
import FreeShippingSvg from '~shared-js/components/FreeShippingSvg';

import { isOnDeal } from './helper';

export default {
  name: 'BlProductCard',
  status: 'prototype',
  release: '0.1.0',
  components: {
    BlBadgeAv,
    BlIconAv,
    BlSpinnerAv,
    BlRatingMv,
    BlCardHeadMv,
    BlCardBodyMv,
    BlMediaMv,
    BlTagAv,
    PhoneIphoneSvg,
    FreeShippingSvg,
  },

  mixins: [imageHelper],

  props: {
    /**
     * The html element used for the button.
     * `button, a`
     */
    tag: {
      type: String,
      default: 'div',
      validator: (value) => value.match(/(div|ul)/),
    },

    product: {
      type: Object,
      default() {
        return null;
      },
    },

    productUrlParams: {
      type: Object,
      default: () => ({}),
    },

    showRating: {
      type: Boolean,
      default: true,
    },

    showOverlay: {
      type: Boolean,
      default: true,
    },

    isFinishedFetchBadgeToggle: {
      type: Boolean,
      default: false,
    },

    isSuperSellerNewBadgeActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      bukamallLogo: 'https://s1.bukalapak.com/pavilion/1586920121610/original/bukamall-logo.png',
    };
  },

  computed: {
    hasDeal() {
      const { deal } = this.product;

      return deal && deal.discount_price && isOnDeal(deal.applied_date, deal.expired_date);
    },

    isDigitalGoods() {
      return this.product.digitalProduct || this.product.digital_product;
    },

    hasWholesale() {
      return this.product.wholesales && this.product.wholesales.length;
    },

    imgUrl() {
      const imgBig = this.product.images.large_urls[0];
      if (imgBig) {
        const u = new URL(imgBig);
        return this.imageUrl(u.href, 'medium');
      }

      return '';
    },

    isBrandSeller() {
      return this.product.store && this.product.store.brand_seller;
    },

    isSuperSeller() {
      return this.product.store && this.product.store.premium_top_seller;
    },

    isSecondProduct() {
      return this.product.condition && this.product.condition.toLowerCase() === 'bekas';
    },

    ratingVal() {
      return this.product.rating.average_rate * 20;
    },

    feedbackUrl() {
      return `${this.product.store.url}/feedback?feedback_as=as_seller&filter_by=all`;
    },

    reviewUrl() {
      return this.product.url ? this.product.url.replace('/p/', '/reviews/') : '';
    },

    storeReview() {
      let percentage = 0;
      let total = 0;

      if (this.product.store && this.product.store.reviews) {
        const { positive } = this.product.store.reviews;
        total = positive + this.product.store.reviews.negative;

        if (positive > 0) {
          percentage = Math.floor((positive / total) * 100);
        }
      }

      return {
        percentage,
        total,
      };
    },
  },

  methods: {
    price(type) {
      if (type === 'discount') {
        return money(this.product.deal.original_price);
      }
      if (type === 'original') {
        return money(this.product.deal.original_price);
      }

      return money(this.product.price);
    },

    handleClick(e) {
      if (global.pdpEventBus) {
        e.preventDefault();
        global.pdpEventBus.publish('changeProduct', {
          product: {
            id: this.product.id,
            name: this.product.name,
            url: this.product.url,
            rating: this.product.rating,
            store: {
              id: this.product.store.id,
              name: this.product.store.name,
            },
          },
        });
      }
      this.$emit('click', this.product);
    },
  },
};
</script>

<style src="./BlProductCardMv.style.scss" lang="scss"></style>
