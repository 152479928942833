<template>
  <div class="bl-tag">
    <div class="bl-tag--wrapper">
      <div class="bl-tag--wrapper--icon">
        <slot name="icon"></slot>
      </div>
      <span class="bl-tag--wrapper--label">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlTagAv',
};
</script>

<style scoped lang="scss">
.bl-tag {
  max-width: 100%;
  height: 16px;
  display: inline-flex;
  align-items: center;
  padding: 2px 4px;
  margin: 2px;
  border-radius: 4px;
  background: #fce6ed;

  &--wrapper {
    display: flex;
    align-items: center;

    &--icon {
      margin-right: 6px;
      display: block;
      width: 13px;
      height: 13px;
      color: #ed0547;
    }

    &--label {
      color: #d1083a;
      font-size: 0.85rem;
      font-weight: 500;
    }
  }
}
</style>
